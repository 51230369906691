import {produce} from 'immer';
import moment from 'moment';
import {persistenceStates} from '@computerrock/formation-core';
import {apsPriceTypes, State, PickupPrice, TimePrice, ServiceFixedPrice, SurchargeRate, Price, PricePeriod, apsTimePriceTariffTypes, apsSurchargeRateTypes, apsFixedRateServiceTypes, getPriceTypeClass} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from './priceManagementActionTypes';

const initialState = {
    timePeriods: [],
    holidays: {},
    areHolidaysLoading: false,
    hasHolidayUpdateFailed: false,
    prices: [],
    pickupPrices: [],
    recoveryPrices: [],
    serviceFixedPrices: [],
    surchargeRatePrices: [],
    timePrices: [],
    defaultPricePeriodTabId: 0,
    fixedPrices: {},
    activeFixedPricePeriods: {},
    latestTimePeriod: {},
    pricesPersistenceState: persistenceStates.PENDING,
};

const updatePriceArrayByType = (prices, priceDTO, uniqueField, priceType) => {
    if (!prices) return;
    const PriceClass = getPriceTypeClass(priceType);

    return prices.map(price => {
        return price[uniqueField] === priceDTO[uniqueField]
            ? new PriceClass().fromDTO(priceDTO)
            : price;
    });
};

const commonElements = (...arrays) => {
    if (arrays.length === 0) {
        return [];
    }
    // Get the first array and use it as a base for comparison
    const baseArray = arrays[0];

    // Filter the elements that are present in all arrays
    const common = baseArray.filter(element => {
        return arrays.every(array => array.includes(element));
    });

    return [...new Set(common)]; // Remove duplicates, if any
};

const priceManagementReducer = produce((draft, action) => {
    switch (action.type) {
        case priceManagementActionTypes.STORE_TIME_PERIODS: {
            const {pricePeriodDTOs = []} = action.payload;
            draft.timePeriods = pricePeriodDTOs.map(pricePeriodDTO => new PricePeriod().fromDTO(pricePeriodDTO));
            if (Array.isArray(pricePeriodDTOs) && pricePeriodDTOs.length) {
                const sortedPeriods = pricePeriodDTOs.sort((a, b) => moment(b.validUntil).diff(moment(a.validUntil)));
                draft.latestTimePeriod = sortedPeriods[0];
            }
            break;
        }

        case priceManagementActionTypes.SET_HOLIDAYS_LOADING: {
            draft.areHolidaysLoading = true;
            break;
        }

        case priceManagementActionTypes.RESET_HOLIDAYS_LOADING: {
            draft.areHolidaysLoading = false;
            break;
        }

        case priceManagementActionTypes.SET_HOLIDAYS_ERROR: {
            draft.hasHolidayUpdateFailed = true;
            break;
        }

        case priceManagementActionTypes.RESET_HOLIDAYS_ERROR: {
            draft.hasHolidayUpdateFailed = false;
            break;
        }

        case priceManagementActionTypes.STORE_STATE_HOLIDAY_DATES: {
            const {stateHolidayDatesDTOs} = action.payload;
            const nationWideHolidayDates = commonElements(...stateHolidayDatesDTOs.map(stateHolidayDatesDTO => {
                return stateHolidayDatesDTO.holidayDates;
            }));
            const currentAllHolidays = draft.holidays['ALL'];

            draft.holidays['ALL'] = currentAllHolidays
                ? currentAllHolidays.fromDTO({
                    code: 'ALL',
                    name: 'Germany',
                    holidayDates: nationWideHolidayDates,
                })
                : new State().fromDTO({
                    code: 'ALL',
                    name: 'Germany',
                    holidayDates: nationWideHolidayDates,
                });

            stateHolidayDatesDTOs.forEach(stateHolidayDatesDTO => {
                const {code} = stateHolidayDatesDTO;
                const currentStateHoliday = draft.holidays[code];
                if (!currentStateHoliday) {
                    draft.holidays[stateHolidayDatesDTO.code] = new State().fromDTO(
                        {
                            ...stateHolidayDatesDTO,
                            holidayDates: stateHolidayDatesDTO.holidayDates.filter(holidayDate => {
                                return !nationWideHolidayDates.includes(holidayDate);
                            }),
                        },
                    );
                    return;
                }

                draft.holidays[stateHolidayDatesDTO.code] = currentStateHoliday.fromDTO({
                    ...stateHolidayDatesDTO,
                    holidayDates: stateHolidayDatesDTO.holidayDates.filter(holidayDate => {
                        return !nationWideHolidayDates.includes(holidayDate);
                    }),
                });
            });
            break;
        }

        case priceManagementActionTypes.STORE_PRICES_BY_TIME: {
            const {pricePeriodDTO} = action.payload;
            const {prices} = pricePeriodDTO;

            draft.prices = prices;

            let surchargeRatePrices = Object.values(apsSurchargeRateTypes).map(surchargeType => new SurchargeRate({
                surchargeType,
                type: apsPriceTypes.SURCHARGE_RATE,
            }));
            let recoveryPrices = [new Price({type: apsPriceTypes.RECOVERY_PRICE})];
            let timePrices = Object.values(apsTimePriceTariffTypes).map(tariff => new TimePrice({
                tariff,
                type: apsPriceTypes.TIME_PRICE,
            }));
            let serviceFixedPrices = Object.values(apsFixedRateServiceTypes).map(serviceType => new ServiceFixedPrice({
                serviceType,
                type: apsPriceTypes.SERVICE_FIXED_PRICE,
            }));
            const pickupPrices = [];

            prices?.forEach(priceDTO => {
                switch (priceDTO.type) {
                    case apsPriceTypes.SURCHARGE_RATE:
                        surchargeRatePrices = updatePriceArrayByType(
                            surchargeRatePrices,
                            priceDTO,
                            'surchargeType',
                            apsPriceTypes.SURCHARGE_RATE,
                        );
                        break;
                    case apsPriceTypes.RECOVERY_PRICE:
                        recoveryPrices = updatePriceArrayByType(
                            recoveryPrices,
                            priceDTO,
                            'type',
                            apsPriceTypes.RECOVERY_PRICE,
                        );
                        break;
                    case apsPriceTypes.TIME_PRICE:
                        timePrices = updatePriceArrayByType(
                            timePrices,
                            priceDTO,
                            'tariff',
                            apsPriceTypes.TIME_PRICE,
                        );
                        break;
                    case apsPriceTypes.SERVICE_FIXED_PRICE:
                        serviceFixedPrices = updatePriceArrayByType(
                            serviceFixedPrices,
                            priceDTO,
                            'serviceType',
                            apsPriceTypes.SERVICE_FIXED_PRICE,
                        );
                        break;
                    case apsPriceTypes.PICKUP_PRICE:
                        pickupPrices.push(new PickupPrice().fromDTO(priceDTO));
                        break;
                    default:
                }
            });

            draft.surchargeRatePrices = surchargeRatePrices;
            draft.recoveryPrices = recoveryPrices;
            draft.timePrices = timePrices;
            draft.serviceFixedPrices = serviceFixedPrices;
            draft.pickupPrices = pickupPrices.sort((priceA, priceB) => priceA.distanceFrom - priceB.distanceFrom);

            break;
        }

        case priceManagementActionTypes.STORE_FIXED_PRICES: {
            const {contractPartnerId, pricePeriodDTOs} = action.payload;
            const currentContractPartner = draft.fixedPrices[contractPartnerId];

            if (currentContractPartner) {
                pricePeriodDTOs.forEach(pricePeriodDTO => {
                    const currentPeriod = currentContractPartner[pricePeriodDTO.id];
                    if (currentPeriod) {
                        currentContractPartner[pricePeriodDTO.id] = currentPeriod.fromDTO(pricePeriodDTO);
                    }
                    if (!currentPeriod) {
                        currentContractPartner[pricePeriodDTO.id] = new PricePeriod().fromDTO(pricePeriodDTO);
                    }
                });
                draft.fixedPrices[contractPartnerId] = currentContractPartner;
                return;
            }
            const newContractPartnerPeriods = {};
            pricePeriodDTOs.forEach(pricePeriodDTO => {
                newContractPartnerPeriods[pricePeriodDTO.id] = new PricePeriod().fromDTO(pricePeriodDTO);
            });
            draft.fixedPrices[contractPartnerId] = newContractPartnerPeriods;
            break;
        }

        case priceManagementActionTypes.SET_ACTIVE_PERIOD_FIXED_PRICE: {
            const {contractPartnerId, pricePeriodDTO} = action.payload;
            draft.activeFixedPricePeriods[contractPartnerId] = pricePeriodDTO;
            break;
        }

        case priceManagementActionTypes.SET_UPDATED_FIXED_PRICES: {
            const {contractPartnerId, pricePeriodDTO, pricePeriodId} = action.payload;
            const currentContractPartnerPrices = draft.fixedPrices[contractPartnerId];
            const updatedPrice = currentContractPartnerPrices[pricePeriodId];

            currentContractPartnerPrices[pricePeriodId] = updatedPrice.fromDTO(pricePeriodDTO);
            draft.fixedPrices[contractPartnerId] = currentContractPartnerPrices;

            break;
        }

        case priceManagementActionTypes.REMOVE_DELETED_FIXED_PRICE: {
            const {contractPartnerId, pricePeriodId} = action.payload;
            const currentContractPartnerPrices = draft.fixedPrices[contractPartnerId];
            delete currentContractPartnerPrices[pricePeriodId];

            draft.fixedPrices[contractPartnerId] = currentContractPartnerPrices;

            break;
        }

        case priceManagementActionTypes.STORE_UPDATED_PERIOD: {
            const {pricePeriodDTO} = action.payload;
            const currentPeriods = draft.timePeriods;
            const replaceAtIndex = currentPeriods.findIndex(currentPeriod => currentPeriod.id === pricePeriodDTO.id);
            currentPeriods.splice(replaceAtIndex, 1, new PricePeriod().fromDTO(pricePeriodDTO));
            draft.timePeriods = currentPeriods;

            if (Array.isArray(currentPeriods) && currentPeriods.length) {
                const sortedPeriods = currentPeriods.sort((a, b) => moment(b.validUntil).diff(moment(a.validUntil)));
                draft.latestTimePeriod = sortedPeriods[0];
            }
            break;
        }

        case priceManagementActionTypes.SET_PRICES_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.pricesPersistenceState = persistenceState;
            break;
        }

        case priceManagementActionTypes.RESET_PRICES_BY_TIME: {
            draft.pickupPrices = [];
            draft.recoveryPrices = [];
            draft.serviceFixedPrices = [];
            draft.surchargeRatePrices = [];
            draft.timePrices = [];
            break;
        }

        default: // no-op
    }
}, initialState);

export default priceManagementReducer;
