import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {ButtonPrimary, Icon, useStyles, warningIcon} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {useTranslate} from '@computerrock/formation-i18n';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import RatesByTimeRow from '../ui-elements/RatesByTimeRow';
import config from '../../config';

const EditRatesByTimeDataV1 = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_rates_by_time_modal');
    const {confirmUpdateRatesByTime, timePrices} = props;
    const [prices, setPrices] = useState([...timePrices]);
    const [errorMessage, setErrorMessage] = useState('');
    const {pricePeriodId} = useSearchQueryParams();

    const handleOnClick = () => {
        if (!prices?.length || !pricePeriodId) return;

        if (Object.values(prices).some(field => field.value === `${config.NOT_ALLOWED_PRICE_VALUE}`)) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');

        confirmUpdateRatesByTime({
            pricePeriodId,
            prices: prices.map(price => ({
                value: parseFloat(price.value),
                type: price.type,
                tariff: price.tariff,
            })),
        });
    };

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell qaIdentPart="edit-rates-by-time-day" colSpan={3}>
                            {translateModal('table_header.day')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-rates-by-time-from" colSpan={2}>
                            {translateModal('table_header.from')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-rates-by-time-to" colSpan={2}>
                            {translateModal('table_header.to')}
                        </TableCell>
                        <TableCell qaIdentPart="edit-rates-by-time-price" colSpan={3}>
                            {translateModal('table_header.price')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {timePrices?.length > 0 && timePrices.map((timePrice, idx) => {
                        return (
                            <RatesByTimeRow
                                key={`${timePrice.id}-${idx}`}
                                prices={prices}
                                setPrices={setPrices}
                                timePrice={timePrice}
                            />
                        );
                    })}
                </TableBody>
            </Table>
            {!!errorMessage && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {errorMessage}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-margin--top-32',
                    'global!ace-u-flex--align-center',
                    'global!ace-u-flex--justify-flex-end',

                ])}
            >
                <ButtonPrimary
                    onClick={handleOnClick}
                    className={cx('global!ace-u-margin--right-32')}
                    isDisabled={prices.some(price => !price.value)}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </div>
    );
};

EditRatesByTimeDataV1.propTypes = {
    confirmUpdateRatesByTime: PropTypes.func.isRequired,
    timePrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        timePrices: state.priceManagement.timePrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateRatesByTime: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRatesByTimeDataV1);
