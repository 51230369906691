import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useSearchQueryParams} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsTimePriceTariffTypes} from '@ace-de/eua-entity-types';
import {ButtonPrimary, Icon, useStyles, warningIcon, Form, InputCurrency} from '@ace-de/ui-components';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import * as priceManagementActionTypes from '../priceManagementActionTypes';

const EditRatesByTimeDataV2 = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_time_rates_modal');
    const {confirmUpdateRatesByTime, timePrices} = props;
    const [errorMessage, setErrorMessage] = useState('');
    const {pricePeriodId} = useSearchQueryParams();

    const handleOnSubmit = formValues => {
        if (!formValues?.[apsTimePriceTariffTypes.WORKDAY_EARLY]
            || !formValues?.[apsTimePriceTariffTypes.SUNDAY_HOLIDAY]
            || !pricePeriodId
        ) return;

        if (!parseFloat(formValues?.[apsTimePriceTariffTypes.WORKDAY_EARLY])
            || !parseFloat(formValues?.[apsTimePriceTariffTypes.SUNDAY_HOLIDAY])
        ) {
            setErrorMessage(translate('global.error_message.zero_values_not_allowed'));
            return;
        }

        setErrorMessage('');

        const updatedPrices = [...timePrices].map(price => {
            const newPrice = {...price};
            if ([apsTimePriceTariffTypes.SUNDAY_HOLIDAY,
                apsTimePriceTariffTypes.WORKDAY_EARLY].includes(price.tariff)) {
                newPrice.value = parseFloat(formValues[price.tariff]);
            }
            return newPrice;
        });

        confirmUpdateRatesByTime({
            pricePeriodId,
            prices: updatedPrices,
        });
    };

    return (
        <div className={cx('global!ace-u-margin--32-0')}>
            <Form name="editTimeRatesForm" onSubmit={handleOnSubmit}>
                {formValues => {
                    return (
                        <Fragment>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell qaIdentPart="edit-rates-by-time-tariff">
                                            {translateModal('table_header.tariff')}
                                        </TableCell>
                                        <TableCell qaIdentPart="edit-rates-by-time-validity">
                                            {translateModal('table_header.validity')}
                                        </TableCell>
                                        <TableCell qaIdentPart="edit-rates-by-time-hours" />
                                        <TableCell
                                            qaIdentPart="edit-rates-by-time-price"
                                            className={cx('global!ace-u-typography--align-right')}
                                        >
                                            {translateModal('table_header.price')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {timePrices?.length > 0
                                    // TODO: filter by version
                                    && timePrices.filter(timePrice => (
                                        [apsTimePriceTariffTypes.WORKDAY_EARLY, apsTimePriceTariffTypes.SUNDAY_HOLIDAY]
                                            .includes(timePrice.tariff)
                                    )).map(timePrice => {
                                        return (
                                            <TableRow qaIdentPart={timePrice.id} key={timePrice.id}>
                                                <TableCell
                                                    qaIdentPart="edit-rates-by-time-day"
                                                    /* eslint-disable-next-line max-len */
                                                    className={timePrice.tariff !== apsTimePriceTariffTypes.WORKDAY_EARLY
                                                        ? 'global!ace-u-typography--vertical-align-baseline' : ''}
                                                >
                                                    {timePrice.tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                                        ? translateModal('tariff_type.tariff_1')
                                                        : translateModal('tariff_type.tariff_2')}
                                                </TableCell>
                                                <TableCell qaIdentPart="edit-rates-by-time-from">
                                                    {timePrice.tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                                        ? translateModal('validity_type.monday_friday')
                                                        : (
                                                            <div
                                                                className={cx([
                                                                    'global!ace-u-flex',
                                                                    'global!ace-u-flex--direction-column',
                                                                ])}
                                                            >
                                                                <p>{translateModal('validity_type.monday_thursday')}</p>
                                                                <p className={cx('global!ace-u-margin--16-0')}>
                                                                    {translateModal('validity_type.weekend')}
                                                                </p>
                                                                <p>{translateModal('validity_type.holidays')}</p>
                                                            </div>
                                                        )}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="edit-rates-by-time-to"
                                                    className={cx('global!ace-u-typography--align-center', {
                                                        'global!ace-u-typography--vertical-align-baseline': timePrice.tariff !== apsTimePriceTariffTypes.WORKDAY_EARLY,
                                                    })}
                                                >
                                                    {timePrice.tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                                        ? translateModal('hours_type.monday_friday')
                                                        : (
                                                            <div
                                                                className={cx([
                                                                    'global!ace-u-flex',
                                                                    'global!ace-u-flex--direction-column',
                                                                ])}
                                                            >
                                                                <p>{translateModal('hours_type.monday_thursday')}</p>
                                                                <p className={cx('global!ace-u-margin--16-0')}>
                                                                    {translateModal('hours_type.weekend')}
                                                                </p>
                                                                <p>{translateModal('hours_type.holidays')}</p>
                                                            </div>
                                                        )}
                                                </TableCell>
                                                <TableCell
                                                    qaIdentPart="edit-rates-by-time-price"
                                                    /* eslint-disable-next-line max-len */
                                                    className={timePrice.tariff !== apsTimePriceTariffTypes.WORKDAY_EARLY
                                                        ? 'global!ace-u-typography--vertical-align-baseline' : ''}
                                                >
                                                    <InputCurrency
                                                        name={timePrice.tariff}
                                                        currencySign={translate('global.currency.eur')}
                                                        className={cx(['ace-c-input-currency--small', 'global!ace-u-full-width'])}
                                                        value={timePrice.value || ''}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {!!errorMessage && (
                                <p
                                    className={cx([
                                        'global!ace-u-flex',
                                        'global!ace-u-margin--top-16',
                                        'global!ace-u-typography--color-warning',
                                        'global!ace-u-typography--variant-caption',
                                        'global!ace-u-flex--align-center',
                                        'global!ace-u-margin--left-16',
                                    ])}
                                >
                                    <Icon
                                        icon={warningIcon}
                                        className={cx([
                                            'global!ace-u-margin--right-8',
                                            'ace-c-icon--s',
                                            'ace-c-icon--color-warning',
                                        ])}
                                    />
                                    <span>
                                        {errorMessage}
                                    </span>
                                </p>
                            )}
                            <div
                                className={cx([
                                    'global!ace-u-width--full',
                                    'global!ace-u-flex',
                                    'global!ace-u-margin--top-32',
                                    'global!ace-u-flex--align-center',
                                    'global!ace-u-flex--justify-flex-end',

                                ])}
                            >
                                <ButtonPrimary
                                    type="submit"
                                    className={cx('global!ace-u-margin--right-32')}
                                    isDisabled={!formValues[apsTimePriceTariffTypes.WORKDAY_EARLY]
                                        || !formValues[apsTimePriceTariffTypes.SUNDAY_HOLIDAY]}
                                >
                                    {translateModal('button_label.save')}
                                </ButtonPrimary>
                            </div>
                        </Fragment>
                    );
                }}
            </Form>
        </div>
    );
};

EditRatesByTimeDataV2.propTypes = {
    confirmUpdateRatesByTime: PropTypes.func.isRequired,
    timePrices: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
    return ({
        timePrices: state.priceManagement.timePrices,
    });
};

const mapDispatchToProps = dispatch => ({
    confirmUpdateRatesByTime: payload => dispatch({
        type: priceManagementActionTypes.CONFIRM_UPDATE_PRICES,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditRatesByTimeDataV2);
