import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Panel, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, editIcon} from '@ace-de/ui-components/icons';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@ace-de/ui-components/data-elements';
import {apsPriceTypes, apsTimePriceTariffTypes} from '@ace-de/eua-entity-types';
import * as priceManagementActionTypes from '../priceManagementActionTypes';
import getPrice from '../../utils/getPrice';
import generateTariffDetails from '../generateTariffDetails';

const RatesByTimePanel = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translatePanel = createTranslateShorthand('rates_by_time_panel');
    const {initiateUpdateRatesByTime, timePrices, isEditingDisabled, pricePeriod} = props;

    return (
        <Panel
            title={(
                <div>
                    {translatePanel('title.rates_by_time')}
                    <p className={cx('global!ace-u-typography--variant-body')}>
                        {translatePanel('subtitle.calculated_every_five_minutes')}
                    </p>
                </div>
            )}
            actions={!isEditingDisabled && (
                <InteractiveIcon
                    icon={editIcon}
                    onClick={() => initiateUpdateRatesByTime({
                        contentType: apsPriceTypes.TIME_PRICE,
                        pricePeriodVersion: pricePeriod.version,
                    })}
                />
            )}
            className={cx('ace-c-panel--full-bleed-content')}
        >
            {pricePeriod.version === 2 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="rates-by-time-tariff">
                                {translatePanel('table_header.tariff')}
                            </TableCell>
                            <TableCell qaIdentPart="rates-by-time-validity">
                                {translatePanel('table_header.validity')}
                            </TableCell>
                            <TableCell />
                            <TableCell qaIdentPart="rates-by-time-hours" />
                            <TableCell qaIdentPart="rates-by-time-price">
                                <div className={cx('global!ace-u-typography--align-right')}>
                                    {translatePanel('table_header.price')}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timePrices.length > 0
                        // TODO: filter by version
                        && timePrices.filter(timePrice => (
                            [apsTimePriceTariffTypes.WORKDAY_EARLY, apsTimePriceTariffTypes.SUNDAY_HOLIDAY]
                                .includes(timePrice.tariff)
                        )).map((timePrice, idx) => {
                            const {id, value, tariff} = timePrice;
                            return (
                                <TableRow
                                    key={`${id}-${idx}`}
                                    qaIdentPart={id}
                                >
                                    <TableCell
                                        qaIdentPart="time-price-tariff"
                                        qaIdentPartPostfix={id}
                                        className={tariff !== apsTimePriceTariffTypes.WORKDAY_EARLY
                                            ? 'global!ace-u-typography--vertical-align-baseline' : ''}
                                    >
                                        {tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                            ? translatePanel('tariff_type.tariff_1')
                                            : translatePanel('tariff_type.tariff_2')}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="time-price-validity"
                                        colSpan={2}
                                    >
                                        {tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                            ? translatePanel('validity_type.monday_friday')
                                            : (
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--direction-column',
                                                    ])}
                                                >
                                                    <p>{translatePanel('validity_type.monday_thursday')}</p>
                                                    <p className={cx('global!ace-u-margin--16-0')}>
                                                        {translatePanel('validity_type.weekend')}
                                                    </p>
                                                    <p>{translatePanel('validity_type.holidays')}</p>
                                                </div>
                                            )}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="time-price-hours"
                                        className={cx([
                                            'global!ace-u-typography--align-center',
                                            'global!ace-u-typography--vertical-align-baseline',
                                        ])}
                                    >
                                        {tariff === apsTimePriceTariffTypes.WORKDAY_EARLY
                                            ? translatePanel('hours_type.monday_friday')
                                            : (
                                                <div
                                                    className={cx([
                                                        'global!ace-u-flex',
                                                        'global!ace-u-flex--direction-column',
                                                    ])}
                                                >
                                                    <p>{translatePanel('hours_type.monday_thursday')}</p>
                                                    <p className={cx('global!ace-u-margin--16-0')}>
                                                        {translatePanel('hours_type.weekend')}
                                                    </p>
                                                    <p>{translatePanel('hours_type.holidays')}</p>
                                                </div>
                                            )}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="time-price-value"
                                        qaIdentPartPostfix={id}
                                        className={tariff !== apsTimePriceTariffTypes.WORKDAY_EARLY
                                            ? 'global!ace-u-typography--vertical-align-baseline' : ''}
                                    >
                                        <div className={cx('global!ace-u-typography--align-right')}>
                                            {value ? getPrice(value, activeLocale) : '-'}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell qaIdentPart="rates-by-time-day" colSpan={2}>
                                {translatePanel('table_header.day')}
                            </TableCell>
                            <TableCell qaIdentPart="rates-by-time-from">
                                {translatePanel('table_header.from')}
                            </TableCell>
                            <TableCell qaIdentPart="rates-by-time-to">
                                {translatePanel('table_header.to')}
                            </TableCell>
                            <TableCell qaIdentPart="rates-by-time-price">
                                <div className={cx('global!ace-u-typography--align-right')}>
                                    {translatePanel('table_header.price')}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {timePrices.length > 0 && timePrices.map((timePrice, idx) => {
                            const {id, value} = timePrice;
                            const tariffDetails = generateTariffDetails(timePrice.tariff);
                            return (
                                <TableRow
                                    key={`${id}-${idx}`}
                                    qaIdentPart={id}
                                >
                                    <TableCell
                                        qaIdentPart="time-price-day"
                                        qaIdentPartPostfix={id}
                                        colSpan={2}
                                    >
                                        {translate(`global.day.${tariffDetails?.days[0].toLowerCase()}`) || '-'}
                                    </TableCell>
                                    <TableCell qaIdentPart="time-price-from">
                                        {tariffDetails?.timeSlot[0] || '-'}
                                    </TableCell>
                                    <TableCell qaIdentPart="time-price-to">
                                        {tariffDetails?.timeSlot[1] || '-'}
                                    </TableCell>
                                    <TableCell
                                        qaIdentPart="time-price-value"
                                        qaIdentPartPostfix={id}
                                    >
                                        <div className={cx('global!ace-u-typography--align-right')}>
                                            {value ? getPrice(value, activeLocale) : '-'}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            )}
        </Panel>
    );
};

RatesByTimePanel.propTypes = {
    initiateUpdateRatesByTime: PropTypes.func.isRequired,
    pricePeriod: PropTypes.object.isRequired,
    timePrices: PropTypes.array.isRequired,
    isEditingDisabled: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        timePrices: state.priceManagement.timePrices,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateUpdateRatesByTime: payload => dispatch({
        type: priceManagementActionTypes.INITIATE_UPDATE_PRICES_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RatesByTimePanel);
