import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {persistenceStates} from '@computerrock/formation-core';
import {useTranslate} from '@computerrock/formation-i18n';
import {useStyles, ContentBlock, ContentItem, DateField, calendarIcon, ButtonPrimary, Form, NoResultsBlock, warningIcon, infoAlertIcon, Icon, HighlightCircle, LoaderSpinner} from '@ace-de/ui-components';
import RatesByTimePanel from './ui-elements/RatesByTimePanel';
import SurchargesPanel from './ui-elements/SurchargesPanel';
import FixedRatesPanel from './ui-elements/FixedRatesPanel';
import SimpleServiceRatesPanel from './ui-elements/SimpleServiceRatesPanel';
import PickupRates from './ui-elements/PickupRatesPanel';
import * as priceManagementActionTypes from './priceManagementActionTypes';

const checkOverlap = (validFrom, validUntil, timePeriods, isEditingValueFromDisabled) => {
    if (!validUntil || !validFrom || !timePeriods) return true;
    const momentFrom = moment(validFrom, 'YYYY-MM-DD');
    const momentUntil = moment(validUntil, 'YYYY-MM-DD');
    const now = moment().format('YYYY-MM-DD');

    if ((isEditingValueFromDisabled && momentUntil.isSameOrBefore(moment(now)))
        || (!isEditingValueFromDisabled && (momentFrom.isBefore(now) || momentUntil.isSameOrBefore(now)))) {
        return true;
    }

    if (momentUntil.isSameOrBefore(momentFrom)) {
        return true;
    }

    return timePeriods.some(timePeriod => {
        const periodFrom = moment(timePeriod.validFrom, 'YYYY-MM-DD');
        const periodUntil = moment(timePeriod.validUntil, 'YYYY-MM-DD');

        return (
            momentFrom.isBetween(periodFrom, periodUntil)
            || momentUntil.isBetween(periodFrom, periodUntil)
            || momentFrom.isSame(periodFrom)
            || momentFrom.isSame(periodUntil)
            || momentUntil.isSame(periodFrom)
            || momentUntil.isSame(periodUntil)
            || periodFrom.isBetween(momentFrom, momentUntil)
            || periodUntil.isBetween(momentFrom, momentUntil)
        );
    });
};

const checkIfTariffPeriodValid = (validFrom, validUntil) => {
    const momentFrom = moment(validFrom);
    const momentUntil = moment(validUntil);

    return momentFrom.year() === 2024 && momentUntil.year() === 2025;
};

const PriceManagementScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('price_management_screen');
    const {history, timePeriods, confirmPricePeriodUpdate, pricesPersistenceState} = props;
    const queryParams = new URLSearchParams(history.location.search);
    const currentPeriod = timePeriods.find(timePeriod => timePeriod.id === parseInt(queryParams.get('pricePeriodId'), 10));
    const otherPeriods = timePeriods.filter(timePeriod => (
        timePeriod.id !== currentPeriod?.id
    ));
    const [pricePeriodFormData, setPricePeriodFormData] = useState();
    const isEditingValidFromDisabled = moment(queryParams.get('validFrom')).isSameOrBefore(moment(), 'day');
    const isEditingValidUntilDisabled = moment(queryParams.get('validUntil')).isBefore(moment(), 'day');
    const [isPeriodDateUpdateDisabled, setIsPeriodDateUpdateDisabled] = useState(true);
    const [isPeriodTariffValid, setIsPeriodTariffValid] = useState(true);

    const handleOnFormChange = formValues => {
        setPricePeriodFormData(formValues);
        const hasOverlap = checkOverlap(
            formValues.validFrom,
            formValues.validUntil,
            otherPeriods,
            isEditingValidFromDisabled,
        );

        const isPeriodTariffValid = !checkIfTariffPeriodValid(formValues.validFrom, formValues.validUntil);

        if (hasOverlap || !isPeriodTariffValid) {
            setIsPeriodTariffValid(false);
            setIsPeriodDateUpdateDisabled(true);
            return;
        }

        setIsPeriodTariffValid(true);
        setIsPeriodDateUpdateDisabled(false);
    };

    const handleOnSubmit = () => {
        if (Object.keys(pricePeriodFormData).length === 0) {
            return;
        }
        const pricePeriodData = {
            ...(pricePeriodFormData?.validFrom && !isEditingValidFromDisabled
                ? {validFrom: moment(pricePeriodFormData.validFrom).format('YYYY-MM-DD')}
                : {}
            ),
            ...(pricePeriodFormData?.validFrom && !isEditingValidUntilDisabled
                ? {validUntil: moment(pricePeriodFormData.validUntil).format('YYYY-MM-DD')}
                : {}),
            prices: [],
        };
        confirmPricePeriodUpdate({pricePeriodId: currentPeriod.id, pricePeriodData});
    };

    return (
        <div>
            <ContentBlock className={cx('global!ace-u-margin--top-8')}>
                {pricesPersistenceState === persistenceStates.PENDING && (
                    <div
                        className={cx([
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-content-center',
                        ])}
                    >
                        <div>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary',
                                    'global!ace-u-margin--top-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                            <p
                                className={cx([
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-margin--bottom-64',
                                    'global!ace-u-typography--variant-h4',
                                ])}
                            >
                                {translateScreen('loader_message.loading')}
                            </p>
                        </div>
                    </div>
                )}
                {((!currentPeriod && pricesPersistenceState === persistenceStates.READY)
                    || pricesPersistenceState === persistenceStates.FAILED)
                    && (
                    <ContentItem>
                        <NoResultsBlock
                            colorVariant={pricesPersistenceState === persistenceStates.FAILED
                                ? 'negative'
                                : 'primary'
                            }
                            icon={(
                                <Icon
                                    className={cx('ace-c-icon--xxl', {
                                        'global!ace-c-icon--color-highlight': pricesPersistenceState !== persistenceStates.FAILED,
                                        'global!ace-c-icon--color-warning': pricesPersistenceState === persistenceStates.FAILED,
                                    })}
                                    icon={infoAlertIcon}
                                />
                            )}
                            message={pricesPersistenceState === persistenceStates.FAILED
                                ? translateScreen('results_error.message')
                                : translateScreen('no_results.message')}
                            description={pricesPersistenceState === persistenceStates.FAILED
                                ? translateScreen('results_error.description')
                                : translateScreen('no_results.description')}
                        />
                    </ContentItem>
                    )}
                {!!currentPeriod && pricesPersistenceState === persistenceStates.READY && (
                    <Fragment>
                        <ContentItem className={cx('ace-c-content-item--span-6')}>
                            <RatesByTimePanel
                                isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                                pricePeriod={currentPeriod}
                            />
                            <ContentBlock>
                                <ContentItem className={cx('ace-c-content-item--span-6')}>
                                    <SurchargesPanel
                                        isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                                    />
                                </ContentItem>
                                <ContentItem className={cx('ace-c-content-item--span-6')}>
                                    <FixedRatesPanel
                                        isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                                    />
                                </ContentItem>
                            </ContentBlock>
                        </ContentItem>
                        <ContentItem className={cx('ace-c-content-item--span-4')}>
                            <SimpleServiceRatesPanel
                                isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                            />
                            <PickupRates
                                isEditingDisabled={isEditingValidFromDisabled || isEditingValidUntilDisabled}
                            />
                        </ContentItem>
                        <ContentItem className={cx('ace-c-content-item--span-2')}>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Form name="pricePeriodForm" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                                    <DateField
                                        name="validFrom"
                                        className={cx('global!ace-u-margin--bottom-8')}
                                        label={translateScreen('date_field_label.valid_from')}
                                        value={currentPeriod?.validFrom || ''}
                                        icon={calendarIcon}
                                        isDisabled={isEditingValidFromDisabled}
                                    />
                                    <DateField
                                        name="validUntil"
                                        className={cx('global!ace-u-margin--bottom-8')}
                                        label={translateScreen('date_field_label.valid_until')}
                                        value={currentPeriod?.validUntil || ''}
                                        icon={calendarIcon}
                                        isDisabled={isEditingValidUntilDisabled}
                                    />
                                    {!isPeriodTariffValid && (
                                        <p
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-margin--bottom-16',
                                                'global!ace-u-typography--color-warning',
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <Icon
                                                icon={warningIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--xl',
                                                    'ace-c-icon--color-warning',
                                                ])}
                                            />
                                            <span>{translateScreen('error_message.mixed_tariff_periods')}</span>
                                        </p>
                                    )}
                                    <ButtonPrimary
                                        name="confirmChanges"
                                        type="submit"
                                        className={cx('global!ace-u-full-width')}
                                        isDisabled={isPeriodDateUpdateDisabled || !pricePeriodFormData}
                                    >
                                        {translateScreen('button_label.save')}
                                    </ButtonPrimary>
                                </Form>
                            </div>
                        </ContentItem>
                    </Fragment>
                )}
            </ContentBlock>
        </div>
    );
};

PriceManagementScreen.propTypes = {
    history: PropTypes.object.isRequired,
    confirmPricePeriodUpdate: PropTypes.func.isRequired,
    timePeriods: PropTypes.array,
    pricesPersistenceState: PropTypes.string.isRequired,
};

PriceManagementScreen.defaultProps = {
    timePeriods: [],
};

const mapStateToProps = state => {
    return {
        timePeriods: state.priceManagement.timePeriods,
        pricesPersistenceState: state.priceManagement.pricesPersistenceState,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        confirmPricePeriodUpdate: payload => dispatch({
            type: priceManagementActionTypes.INITIATE_PRICE_PERIOD_UPDATE,
            payload,
        }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceManagementScreen);
